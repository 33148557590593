import React, { useCallback, useEffect, useState } from 'react';
import PageWrapper from '../../../../layout/PageWrapper/PageWrapper';
import Page from '../../../../layout/Page/Page';
import TemplateReportCard from '../../../_common/TemplateReportCard';
import BeveledCone from '../../../../assets/ainovaLogo/ainova-low-resolution-logo-black-on-white-background.png';
import { ADMIN_GET_REPORT_TEMPLATES } from '../../../../Api/Query';
import { useMutation, useQuery } from '@apollo/client';
import Spinner from '../../../../components/bootstrap/Spinner';
import NoData from '../../../../assets/svgImg/nodata.svg';
import Bug from '../../../../assets/svgImg/bug.svg';
import { DELETE_REPORT_TEMPLATE, PUBLISH_REPORT_TEMPLATE } from '../../../../Api/Mutation';
import showNotification from '../../../../components/extras/showNotification';
import Modals from '../../../../components/modal/Modals';

const AdminReportTemplates = () => {
	const { loading, error, data } = useQuery(ADMIN_GET_REPORT_TEMPLATES, {
		variables: { offset: 0, limit: 10 },
		fetchPolicy: 'network-only',
	});
	const [reportTemplates, setReportTemplates] = useState([]);
	const [publishTemplate] = useMutation(PUBLISH_REPORT_TEMPLATE);
	const [deleteReportTemplate] = useMutation(DELETE_REPORT_TEMPLATE);

	const [selectedReportId, setSelectedReportId] = useState<string | null>(null);
	const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
	const [deleteLoading, setIsDeleteLoading] = useState<boolean>(false);

	useEffect(() => {
		if (!!data) {
			setReportTemplates(data.getAllReportTemplates.reportTemplates);
		}
	}, [data]);

	const handlePublish = useCallback(
		async (id: string | number, type: boolean) => {
			try {
				const response = await publishTemplate({
					variables: { id, type },
				});
				if (response?.data?.publishTemplateById?.success) {
					showNotification(
						<span className='d-flex align-items-center'>
							<span>{type ? 'Published' : 'Unpublished'} successfully.</span>
						</span>,
						`The template was ${type ? 'published' : 'unpublished'}.`,
					);
				}
			} catch (error) {
				console.error('Error publishing template:', error);
			}
		},
		[publishTemplate],
	);

	const handleDeleteClick = (reportId: number | string) => {
		setSelectedReportId(reportId as string);
		setIsModalOpen(true);
	};

	const handleConfirmDelete = useCallback(async () => {
		if (!selectedReportId) return;

		setIsDeleteLoading(true);
		try {
			const response = await deleteReportTemplate({ variables: { id: selectedReportId } });
			if (response.data.deleteReportTemplate.success) {
				showNotification(
					<span className='d-flex align-items-center'>
						<span>Deleted successfully.</span>
					</span>,
					'The report template is deleted successfully',
				);
				setReportTemplates((state) =>
					state.filter((item: any) => item.id !== selectedReportId),
				);
			} else {
				showNotification(
					<span className='d-flex align-items-center'>
						<span>Operation Unsuccessfull</span>
					</span>,
					'Failed to delete the report template.',
				);
			}
		} catch (error) {
			console.error('Error deleting report template:', error);
			showNotification(
				<span className='d-flex align-items-center'>
					<span>Operation Unsuccessfull</span>
				</span>,
				'An error occurred while deleting the report template.',
			);
		} finally {
			setIsModalOpen(false);
			setSelectedReportId(null);
			setIsDeleteLoading(false);
		}
	}, [selectedReportId]);

	if (loading)
		return (
			<div
				className='d-flex align-items-center justify-content-center'
				style={{ height: '80vh' }}>
				<Spinner isGrow={true} color={'dark'} size={'5vh'} />
				<span style={{ paddingLeft: '10px' }}>Loading Templates...</span>
			</div>
		);

	if (error) {
		return (
			<div
				className='d-flex flex-column align-items-center justify-content-center'
				style={{ height: '80vh' }}>
				<img height='300' src={Bug} />
				<h2 style={{ textAlign: 'center' }}>
					Oops, something went wrong! Please give it another try.
				</h2>
			</div>
		);
	}

	return (
		<PageWrapper title='Reports Templates List'>
			{!!reportTemplates.length ? (
				<Page>
					<div className='display-6 fw-bold py-3'>Report Templates</div>
					<div className='row'>
						{reportTemplates.map((item: any) => {
							return (
								<div key={item.id} className='col-xxl-3 col-xl-4 col-md-6 py-3'>
									<TemplateReportCard
										category={item?.templateReportTypes?.reportCategory}
										type={item?.templateReportTypes?.reportType}
										id={item.id}
										name={item.title}
										img={item.template_image || BeveledCone}
										desc={item?.templateReportTypes?.reportDescription}
										showDropdown={true}
										isAdmin={true}
										isPublished={item?.is_published}
										status={item?.status}
										handlePublish={handlePublish}
										handleDelete={handleDeleteClick}
									/>
								</div>
							);
						})}
					</div>
				</Page>
			) : (
				<div
					className='d-flex flex-column align-items-center justify-content-center'
					style={{ height: '80vh' }}>
					<img height='300' src={NoData} />
					<h2 style={{ textAlign: 'center' }}>No Templates Found</h2>
				</div>
			)}
			<Modals
				open={isModalOpen}
				setOpen={setIsModalOpen}
				title='Delete Report?'
				message='Are you sure you want to delete the report?'
				deleted={handleConfirmDelete}
				icon='delete'
				text='Delete'
				loading={deleteLoading}
				// onClose={handleCloseModal}
			/>
		</PageWrapper>
	);
};

export default AdminReportTemplates;
