import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import Page from '../../../layout/Page/Page';
import TemplateReportCard from '../../_common/TemplateReportCard';
import BeveledCone from '../../../assets/ainovaLogo/ainova-low-resolution-logo-black-on-white-background.png';
import NoData from '../../../assets/svgImg/nodata.svg';
import Bug from '../../../assets/svgImg/bug.svg';
import Spinner from '../../../components/bootstrap/Spinner';
import { GET_REPORT_TEMPLATES } from '../../../Api/Query';
import { useQuery } from '@apollo/client';
import Logo from '../../../assets/ainovaLogo/ainova-low-resolution-logo-black-on-white-background.png'
import Card, { CardBody } from '../../../components/bootstrap/Card';

const ReportTemplates = () => {
	const { loading, error, data } = useQuery(GET_REPORT_TEMPLATES, {
		variables: { offset: 0, limit: 10 },
		fetchPolicy: 'network-only',
	});
	if (loading)
		return (
			<div
				className='d-flex align-items-center justify-content-center'
				style={{ height: '100vh' }}>
				<Spinner isGrow={true} color={'dark'} size={'5vh'} />
				<span style={{ paddingLeft: '10px' }}>Loading Templates...</span>
			</div>
		);

	if (error) {
		return (
			<div
				className='d-flex flex-column align-items-center justify-content-center'
				style={{ height: '100vh' }}>
				<img height='300' src={Bug} />
				<h2 style={{ textAlign: 'center' }}>
					Oops, something went wrong! Please give it another try.
				</h2>
			</div>
		);
	}

	return (
		<PageWrapper title='Reports Templates'>
			{!!data.getAllReportTemplatesClientSide.reportTemplates.length ? (
				<Page>
					<div className='h-100'>
						<div className='w-100 d-flex justify-content-center'>
							<div className='col-md-4 toogleDiv'>
								<Card stretch className='bg-transparent w-100' shadow='none'>
									<CardBody>
										<div className='h-100 d-flex align-items-center justify-content-center'>
											<div className='row text-center gap-3'>
												<div className='col-12 w-50 m-auto'>
													<img
														src={Logo}
														alt='AINOVALOGO'
														className='w-100'
													/>
												</div>
												<div className='col-12 text-center text-uppercase h2 fw-bold mb-2  display-6'>
													Report Templates
												</div>
											</div>
										</div>
									</CardBody>
								</Card>
							</div>
						</div>

						<div className='row'>
							{data.getAllReportTemplatesClientSide.reportTemplates.map(
								(item: any) => {
									return (
										<div key={item.id} className='col-xxl-3 col-xl-4 col-md-6'>
											<TemplateReportCard
												category={item?.templateReportTypes?.reportCategory}
												type={item?.templateReportTypes?.reportType}
												id={item.id}
												name={item.title}
												img={item.template_image || BeveledCone}
												desc={item?.templateReportTypes?.reportDescription}
											/>
										</div>
									);
								},
							)}
						</div>
					</div>
				</Page>
			) : (
				<div
					className='d-flex flex-column align-items-center justify-content-center'
					style={{ height: '100vh' }}>
					<img height='300' src={NoData} />
					<h2 style={{ textAlign: 'center' }}>No Templates Found</h2>
				</div>
			)}
		</PageWrapper>
	);
};

export default ReportTemplates;
