import { useMutation } from '@apollo/client';
import { useFormik } from 'formik';
import React, { FC, useEffect, useState } from 'react';
import { UPDATE_REPORT_TYPE } from '../../../../Api/Mutation';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import Textarea from '../../../../components/bootstrap/forms/Textarea';
import FormGroup from '../../../../components/bootstrap/forms/FormGroup';
import Wizard, { WizardItem } from '../../../../components/Wizard';
import Button from '../../../../components/bootstrap/Button';
import Input from '../../../../components/bootstrap/forms/Input';
import Select from '../../../../components/bootstrap/forms/Select';
import Option from '../../../../components/bootstrap/Option';
import PageWrapper from '../../../../layout/PageWrapper/PageWrapper';
import Page from '../../../../layout/Page/Page';
import { model } from '../../../../LocalData/TableData';
import { useNavigate, useParams } from 'react-router-dom';
import { GETALLREPORTSTYPE, MESSAGE, STATUS } from '../../../../reducer/Types';
import PreviewItem from '../../../../components/previewItem/PreviewItem';
import { useWindowSize } from 'react-use';
import '../../../../styles/pages/UpdateCreateReportType.scss';
import Checks from '../../../../components/bootstrap/forms/Checks';

const UpdateCreateReportType = () => {
	const { user, getAllReportType } = useSelector((state: any) => state);
	const initialQuestion = {
		question: '',
		shortCode: '',
		maxLength: 0,
		minLength: 0,
		enableFileInput: false,
	};
	const [activeItemIndex, setActiveItemIndex] = useState<any>(0);
	const [isDisabled, setIsDisabled] = useState<any>(true);
	const [questions, setQuestions] = useState([initialQuestion]);
	const [delQuestions, setDelQuestions] = useState<any>([]);
	const [addReportType, { loading }] = useMutation(UPDATE_REPORT_TYPE);
	const dispatch = useDispatch();
	const { id } = useParams();
	const navigate = useNavigate();
	const { width } = useWindowSize();
	const [reportUrl, setReportUrl] = useState(
		 'https://app.ainova.io/questionnaire?id=' + id,
	);

	const [isCopiedReportUrl, setIsCopiedReportUrl] = useState(false);

	const handleInputChange = (event: any) => {
		setReportUrl(event.target.value);
	};

	const handleCopyClick = () => {
		if (reportUrl) {
			setIsCopiedReportUrl(true);
			navigator.clipboard
				.writeText(reportUrl)
				.then(() => {
					console.log('Text copied to clipboard:', reportUrl);
					setTimeout(() => {
						setIsCopiedReportUrl(false);
					}, 5000);
				})
				.catch((error) => {
					console.error('Error copying text:', error);
					setIsCopiedReportUrl(false);
				});
		}
	};

	const mobWidth = width < Number(process.env.REACT_APP_MOBILE_BREAKPOINT_SIZE);

	const firstArr = getAllReportType && getAllReportType?.find((Ele: any) => Ele?.id === id);

	const formik: any = useFormik({
		initialValues: {
			ReportTitle: firstArr?.reportTitle || '',
			reportType: firstArr?.reportType || '',
			reportCategory: firstArr?.reportCategory || '',
			outputBtnLabel: firstArr?.outputButtonLabel || '',
			maxTokensToBeUsed: firstArr?.maxTokens || 0,
			temprature: firstArr?.temperature || 0,
			reportDescription: firstArr?.reportDescription || '',
			model: firstArr?.model || '',
			order: firstArr?.order || 0,
			prompt: firstArr?.prompt || '',
			questions: firstArr && firstArr.questions ? firstArr.questions : [...questions],
		},

		validationSchema: Yup.object().shape({
			ReportTitle: Yup.string().required('Required'),
			reportType: Yup.string().required('Required'),
			model: Yup.string().required('Required'),
			reportCategory: Yup.string().required('Required'),
			outputBtnLabel: Yup.string().required('Required'),
			maxTokensToBeUsed: Yup.string().required('Required'),
			temprature: Yup.string().required('Required'),
			reportDescription: Yup.string().required('Required'),
			prompt: Yup.string().required('Required'),
		}),

		onSubmit: (values) => {
			let moreQuestions = formik?.values?.questions
				?.filter(
					(item: any) => !firstArr.questions.some((ele: any) => ele?.id === item?.id),
				)
				.map((item: any) => {
					return {
						...item,
						maxLength: item?.maxLength || 0,
						minLength: item?.minLength || 0,
					};
				});
			let reportQuestions = formik?.values?.questions?.filter((item: any) => {
				return item?.id;
			});

			let del = delQuestions.map((e: any) => {
				return e.id;
			});
			const previousQuestion = reportQuestions.map((item: any) => {
				return {
					question: item?.question,
					shortCode: item?.shortCode,
					id: item?.id,
					maxLength: item?.maxLength || 0,
					minLength: item?.minLength || 0,
					enableFileInput: item?.enableFileInput,
				};
			});

			addReportType({
				variables: {
					updateReportTypeId: id,
					reportTitle: values.ReportTitle,
					reportDescription: values.reportDescription,
					reportCategory: values.reportCategory,
					outputButtonLabel: values.outputBtnLabel,
					prompt: values.prompt,
					maxTokens: values.maxTokensToBeUsed,
					temperature: values.temprature,
					adminId: user?.id,
					reportType: values.reportType.toLowerCase(),
					model: values.model,
					order: values.order,
					questions: previousQuestion,
					deletedQuestions: del,
					addMoreQuestions: moreQuestions,
				},
			})
				.then(({ data }) => {
					if (!!data.updateReportType.success) {
						dispatch({ type: MESSAGE, payload: data.updateReportType.message });
						dispatch({ type: STATUS, payload: true });
						const updatedArray = getAllReportType.filter((item: any) => {
							return item.id !== id;
						});
						updatedArray.push(data.updateReportType.updatedReport);
						dispatch({
							type: GETALLREPORTSTYPE,
							payload: updatedArray,
						});
						navigate('/');
					} else {
						if (!data.updateReportType.success) {
							dispatch({ type: MESSAGE, payload: data.updateReportType.message });
							dispatch({ type: STATUS, payload: false });
						}
					}
				})
				.catch((err) => {
					console.log(err);
				});
		},
	});

	const handleAddQuestion = () => {
		const updatedQuestions = [...formik.values.questions, initialQuestion];
		formik.setFieldValue('questions', updatedQuestions);
		setQuestions(updatedQuestions);
	};

	const handleRemoveQuestion = (index: any) => {
		const updatedQuestions = [...formik.values.questions];
		const deletedQuestion = updatedQuestions.splice(index, 1)[0];
		formik.setFieldValue('questions', updatedQuestions);
		setQuestions(updatedQuestions);
		setDelQuestions([...delQuestions, deletedQuestion]);
	};

	const allNamesAreValid = formik.values.questions.every(
		(obj: any) => obj.question.trim() !== '' && obj.shortCode.trim() !== '',
	);

	const disabled = () => {
		if (
			!activeItemIndex &&
			!!formik.values.ReportTitle &&
			!!formik.values.reportType &&
			!!formik.values.reportCategory &&
			!!formik.values.outputBtnLabel &&
			!!formik.values.maxTokensToBeUsed &&
			!!formik.values.temprature &&
			!!formik.values.reportDescription
		) {
			setIsDisabled(false);
		} else if (formik.values.prompt) {
			setIsDisabled(false);
		} else if (allNamesAreValid) {
			setIsDisabled(false);
			return;
		} else {
			setIsDisabled(true);
		}
	};

	useEffect(() => {
		disabled();
	}, [
		activeItemIndex,
		formik.values.ReportTitle,
		formik.values.reportType,
		formik.values.reportCategory,
		formik.values.outputBtnLabel,
		formik.values.maxTokensToBeUsed,
		formik.values.temprature,
		formik.values.reportDescription,
		!!allNamesAreValid,
		!!formik.values.prompt,
	]);

	const handleTemperature = (e: any) => {
		const { name, value } = e.target;
		const parsedValue = parseFloat(value);
		if (parsedValue > 2) {
			formik.setFieldValue(name, 2);
		} else if (parsedValue < 0) {
			formik.setFieldValue(name, 0);
		} else {
			formik.setFieldValue(name, parsedValue);
		}
	};

	const handleOrder = (e: any) => {
		const { name, value } = e.target;
		const parsedValue = parseInt(value);
		if (parsedValue < 0) {
			formik.setFieldValue(name, 0);
		} else {
			formik.setFieldValue(name, parsedValue);
		}
	};

	const handleKeyDown = (e: any) => {
		if (e.key === '.' || e.key === 'e') {
			e.preventDefault();
		}
	};

	return (
		<PageWrapper title='Update-Create-Report-Type'>
			<Page>
				<Wizard
					buttonLable='Update'
					isHeader
					stretch
					activeItemIndex={activeItemIndex}
					Loading={loading}
					buttonDisabled={isDisabled}
					setActiveItemIndex={setActiveItemIndex}
					onSubmit={formik.handleSubmit}>
					<WizardItem id='step1' title='Update Report Detail'>
						<div className='row g-4'>
							<div className='col-md-6'>
								<FormGroup id='ReportTitle' label='Report title' isFloating>
									<Input
										autoComplete='ReportTitle'
										value={formik.values.ReportTitle}
										isTouched={formik.touched.ReportTitle}
										invalidFeedback={formik.errors.ReportTitle}
										isValid={formik.isValid}
										onChange={formik.handleChange}
										onBlur={formik.handleBlur}
										onFocus={() => {
											formik.setErrors({});
										}}
									/>
								</FormGroup>
							</div>
							<div className='col-md-6'>
								<FormGroup id='reportCategory' label='Report Category' isFloating>
									<Input
										autoComplete='reportCategory'
										value={formik.values.reportCategory}
										isTouched={formik.touched.reportCategory}
										invalidFeedback={formik.errors.reportCategory}
										isValid={formik.isValid}
										onChange={formik.handleChange}
										onBlur={formik.handleBlur}
										onFocus={() => {
											formik.setErrors({});
										}}
									/>
								</FormGroup>
							</div>
							<div className='col-md-6'>
								<FormGroup id='outputBtnLabel' label='Output btn label' isFloating>
									<Input
										autoComplete='outputBtnLabel'
										value={formik.values.outputBtnLabel}
										isTouched={formik.touched.outputBtnLabel}
										invalidFeedback={formik.errors.outputBtnLabel}
										isValid={formik.isValid}
										onChange={formik.handleChange}
										onBlur={formik.handleBlur}
										onFocus={() => {
											formik.setErrors({});
										}}
									/>
								</FormGroup>
							</div>
							<div className='col-md-6'>
								<FormGroup
									id='maxTokensToBeUsed'
									label='Max tokens to be used (number)'
									isFloating>
									<Input
										type='number'
										autoComplete='maxTokensToBeUsed'
										value={formik.values.maxTokensToBeUsed}
										isTouched={formik.touched.maxTokensToBeUsed}
										invalidFeedback={formik.errors.maxTokensToBeUsed}
										isValid={formik.isValid}
										onChange={formik.handleChange}
										onBlur={formik.handleBlur}
										onFocus={() => {
											formik.setErrors({});
										}}
									/>
								</FormGroup>
							</div>
							<div className='col-md-6'>
								<FormGroup id='temprature' label='Temprature' isFloating>
									<Input
										type='number'
										name='temprature'
										autoComplete='temprature'
										value={formik.values.temprature}
										isTouched={formik.touched.temprature}
										invalidFeedback={formik.errors.temprature}
										isValid={formik.isValid}
										onChange={handleTemperature}
										onBlur={formik.handleBlur}
										onFocus={() => {
											formik.setErrors({});
										}}
									/>
								</FormGroup>
							</div>
							<div className='col-md-6'>
								<FormGroup id='order' label='Order' isFloating>
									<Input
										type='number'
										name='order'
										autoComplete='order'
										value={formik.values.order}
										isTouched={formik.touched.order}
										invalidFeedback={formik.errors.order}
										isValid={formik.isValid}
										onChange={handleOrder}
										onKeyDown={handleKeyDown}
										onBlur={formik.handleBlur}
										onFocus={() => {
											formik.setErrors({});
										}}
									/>
								</FormGroup>
							</div>
							<div className='col-md-6'>
								<Select
									id='select'
									size='lg'
									name='reportType'
									ariaLabel='Per'
									value={formik.values.reportType}
									isTouched={formik.touched.reportType}
									invalidFeedback={formik.errors.reportType}
									isValid={formik.isValid}
									onBlur={formik.handleBlur}
									onFocus={() => {
										formik.setErrors({});
									}}
									onChange={formik.handleChange}
									placeholder='Select a Reports Type'>
									<Option disabled>Select a Reports Type</Option>

									<Option value='simple'>Simple</Option>
									<Option value='standard'>Standard</Option>
									<Option value='advanced'>Advanced</Option>
								</Select>
							</div>
							<div className='col-md-6'>
								<Select
									id='select'
									size='lg'
									name='model'
									ariaLabel='Per'
									value={formik.values.model}
									isTouched={formik.touched.model}
									invalidFeedback={formik.errors.model}
									isValid={formik.isValid}
									onBlur={formik.handleBlur}
									onFocus={() => {
										formik.setErrors({});
									}}
									onChange={formik.handleChange}
									placeholder='Select a Model'>
									<Option disabled>Select a Model</Option>
									{/* <Option value='GPT_TURBO'>gpt-3.5-turbo</Option> */}
									{/* <Option value='GPT_TURBO_16k'>gpt-3.5-turbo-16k</Option> */}
									{/* <Option value='DAVINCI_003'>text-davinci-003</Option> */}
									<Option value='GPT_4'>gpt-4</Option>
									<Option value='GPT_4o_MINI'>gpt-4o-mini</Option>
									<Option value='CLAUDE_SONNET'>
										claude-3-5-sonnet-20240620
									</Option>
								</Select>
							</div>
							<div className='col-md-12'>
								<Textarea
									rows={3}
									id='reportDescription'
									placeholder='Report description'
									autoComplete='reportDescription'
									value={formik.values.reportDescription}
									isTouched={formik.touched.reportDescription}
									invalidFeedback={formik.errors.reportDescription}
									isValid={formik.isValid}
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
									onFocus={() => {
										formik.setErrors({});
									}}
								/>
							</div>
							<div className='col-md-12'>
								<FormGroup id='outputBtnLabel' label='Report Url' isFloating>
									<Input
										autoComplete='outputBtnLabel'
										value={reportUrl}
										disabled
										// isTouched={formik.touched.outputBtnLabel}
										// invalidFeedback={formik.errors.outputBtnLabel}
										// isValid={formik.isValid}
										onChange={handleInputChange}
										// onBlur={formik.handleBlur}
										// onFocus={() => {
										// 	formik.setErrors({});
										// }}
									/>
									<div
										className='copyButtonContainer'
										style={isCopiedReportUrl ? { width: 70 } : {}}
										onClick={handleCopyClick}>
										<span className='copyButton'>
											{isCopiedReportUrl ? 'Copied' : 'Copy'}
										</span>
									</div>
								</FormGroup>
							</div>
						</div>
					</WizardItem>
					<WizardItem id='step2' title='Update Report Question'>
						<div className='row g-4'>
							{formik?.values?.questions?.map((item: any, index: any) => {
								return (
									<div className='questionAndShortCode'>
										<div className='questionAndShortCodeInput'>
											<FormGroup
												id={`questions.${index}.question`}
												label='Question'
												isFloating>
												<Input
													name={`questions.${index}.question`}
													value={formik.values.questions[index]?.question}
													onChange={formik.handleChange}
												/>
											</FormGroup>
											<FormGroup
												id={`questions.${index}.shortCode`}
												label='Question shortCode'
												isFloating>
												<Input
													name={`questions.${index}.shortCode`}
													value={
														formik.values.questions[index]?.shortCode
													}
													onChange={formik.handleChange}
												/>
											</FormGroup>
										</div>
										<div className='questionAndShortCodeLength'>
											<FormGroup
												id={`questions.${index}.maxLength`}
												label='Max Length'
												isFloating>
												<Input
													defaultValue={0}
													type='number'
													name={`questions.${index}.maxLength`}
													value={
														formik.values.questions[index]?.maxLength ||
														0
													}
													onChange={formik.handleChange}
												/>
											</FormGroup>
											<FormGroup
												id={`questions.${index}.minLength`}
												label='Min Length'
												isFloating>
												<Input
													defaultValue={0}
													type='number'
													name={`questions.${index}.minLength`}
													value={
														formik.values.questions[index]?.minLength ||
														0
													}
													onChange={formik.handleChange}
												/>
											</FormGroup>
											<div className='col-md-2 w-auto toggle'>
												<label>Enable File Input</label>
												<Checks
													type='switch'
													checked={
														formik.values.questions[index]
															?.enableFileInput
													}
													onChange={formik.handleChange}
													name={`questions.${index}.enableFileInput`}
												/>
											</div>
										</div>

										<Button
											color='danger'
											isLight
											onClick={() => handleRemoveQuestion(index)}>
											Remove
										</Button>
									</div>
								);
							})}
							<div>
								<Button
									color='dark'
									icon='Add'
									size={'lg'}
									isLight
									onClick={handleAddQuestion}>
									Add
								</Button>
							</div>
						</div>
					</WizardItem>
					<WizardItem id='step3' title='Update Report Prompt'>
						<Textarea
							id='prompt'
							placeholder='Prompt'
							rows={15}
							autoComplete='prompt'
							value={formik.values.prompt}
							isTouched={formik.touched.prompt}
							invalidFeedback={formik.errors.prompt}
							isValid={formik.isValid}
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							onFocus={() => {
								formik.setErrors({});
							}}
						/>
					</WizardItem>
					<WizardItem id='step4' title='Update Preview'>
						<div className='row g-3'>
							<div className='col-9 offset-3'>
								<h4 className='mt-4'>Report Detail</h4>
							</div>

							<PreviewItem
								mobWidth={mobWidth}
								title='Report title'
								value={formik.values.ReportTitle}
							/>
							<PreviewItem
								mobWidth={mobWidth}
								title='Report Category'
								value={formik.values.reportCategory}
							/>
							<PreviewItem
								mobWidth={mobWidth}
								title='Output btn label'
								value={formik.values.outputBtnLabel}
							/>
							<PreviewItem
								mobWidth={mobWidth}
								title={`max tokens to be used ${mobWidth ? `(num)` : `(number)`}`}
								value={formik.values.maxTokensToBeUsed}
							/>
							<PreviewItem
								mobWidth={mobWidth}
								title='Temperature'
								value={formik.values.temprature}
							/>
							<PreviewItem
								mobWidth={mobWidth}
								title='Report Type'
								value={formik.values.reportType}
							/>
							<PreviewItem
								mobWidth={mobWidth}
								title='Model'
								value={model?.[formik.values.model]}
							/>
							<PreviewItem
								mobWidth={mobWidth}
								title='Order'
								value={formik.values.order}
							/>
							<PreviewItem
								mobWidth={mobWidth}
								title='Report description'
								value={formik.values.reportDescription}
							/>

							<div className='col-9 offset-3'>
								<h3 className='mt-4'>Report Question</h3>
							</div>
							{formik?.values?.questions.map((ele: any, i: any) => {
								return (
									<>
										<PreviewItem
											mobWidth={mobWidth}
											title={`${i + 1}. Question`}
											value={ele?.question}
										/>
										<PreviewItem
											mobWidth={mobWidth}
											title={`${i + 1}. Question Short codes`}
											value={ele?.shortCode}
										/>
										<PreviewItem
											mobWidth={mobWidth}
											title={`${i + 1}. Max Length`}
											value={ele?.maxLength}
										/>
										<PreviewItem
											mobWidth={mobWidth}
											title={`${i + 1}. Min Length`}
											value={ele?.minLength}
										/>
									</>
								);
							})}

							<div className='col-9 offset-3'>
								<h3 className='mt-4'>Report Prompt</h3>
							</div>
							<PreviewItem
								mobWidth={mobWidth}
								title='Prompt'
								value={formik.values.prompt}
							/>
						</div>
					</WizardItem>
				</Wizard>
			</Page>
		</PageWrapper>
	);
};

export default UpdateCreateReportType;
