import React, { FC, useEffect, useMemo, useState } from 'react';
import PageWrapper from '../../../../layout/PageWrapper/PageWrapper';
import Page from '../../../../layout/Page/Page';
import Wizard, { WizardItem } from '../../../../components/Wizard';
import FormGroup from '../../../../components/bootstrap/forms/FormGroup';
import Input from '../../../../components/bootstrap/forms/Input';
import Textarea from '../../../../components/bootstrap/forms/Textarea';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Button from '../../../../components/bootstrap/Button';
import { useMutation } from '@apollo/client';
import { ADD_REPORT_TYPE } from '../../../../Api/Mutation';
import { useDispatch, useSelector } from 'react-redux';
import Select from '../../../../components/bootstrap/forms/Select';
import Option from '../../../../components/bootstrap/Option';
import { model } from '../../../../LocalData/TableData';
import { useNavigate } from 'react-router-dom';
import { MESSAGE, STATUS } from '../../../../reducer/Types';
import '../../../../styles/pages/createReportType.scss';
import PreviewItem from '../../../../components/previewItem/PreviewItem';
import Checks from '../../../../components/bootstrap/forms/Checks';

const CreateReportType = () => {
	const { id } = useSelector((state: any) => state?.user);
	const initialQuestion = {
		question: '',
		shortCode: '',
		maxLength: 0,
		minLength: 0,
		enableFileInput: false,
	};

	const [activeItemIndex, setActiveItemIndex] = useState<any>(0);
	const [isDisabled, setIsDisabled] = useState<any>(true);
	const [questions, setQuestions] = useState([initialQuestion]);
	const [addReportType, { loading, error }] = useMutation(ADD_REPORT_TYPE);
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const formik = useFormik({
		initialValues: {
			ReportTitle: '',
			reportType: '',
			reportCategory: '',
			outputBtnLabel: '',
			maxTokensToBeUsed: 0,
			temprature: 0,
			order: 0,
			reportDescription: '',
			model: '',
			prompt: '',
			questions: [...questions],
		},
		validationSchema: Yup.object().shape({
			ReportTitle: Yup.string().required('Required'),
			reportType: Yup.string().required('Required'),
			model: Yup.string().required('Required'),
			reportCategory: Yup.string().required('Required'),
			outputBtnLabel: Yup.string().required('Required'),
			maxTokensToBeUsed: Yup.string().required('Required'),
			temprature: Yup.string().required('Required'),
			reportDescription: Yup.string().required('Required'),
			prompt: Yup.string().required('Required'),
		}),
		onSubmit: (values) => {
			const updateQuestion = values.questions?.map((ele) => {
				return {
					...ele,
					maxLength: ele?.maxLength || 0,
					minLength: ele?.minLength || 0,
				};
			});

			addReportType({
				variables: {
					reportTitle: values.ReportTitle,
					reportDescription: values.reportDescription,
					reportType: values.reportType.toLowerCase(),
					reportCategory: values.reportCategory,
					outputButtonLabel: values.outputBtnLabel,
					prompt: values.prompt,
					maxTokens: values.maxTokensToBeUsed,
					temperature: values.temprature,
					adminId: id,
					model: values.model,
					questions: updateQuestion,
					order: values.order,
				},
			})
				.then(({ data }) => {
					if (!!data.addReportType.success) {
						dispatch({ type: MESSAGE, payload: data.addReportType.message });
						dispatch({ type: STATUS, payload: true });
						formik.resetForm();
						navigate('/');
					} else {
						if (!data.addReportType.success) {
							dispatch({ type: MESSAGE, payload: data.addReportType.message });
							dispatch({ type: STATUS, payload: false });
						}
					}
				})
				.catch((err) => {
					console.log(err);
				});
		},
	});

	const handleAddQuestion = () => {
		const updatedQuestions = [...formik.values.questions, initialQuestion];
		formik.setFieldValue('questions', updatedQuestions);
		setQuestions(updatedQuestions);
	};

	const handleRemoveQuestion = (index: any) => {
		const updatedQuestions = [...formik.values.questions];
		updatedQuestions.splice(index, 1);
		formik.setFieldValue('questions', updatedQuestions);
		setQuestions(updatedQuestions);
	};

	const allNamesAreValid = formik.values.questions.every(
		(obj) =>
			typeof obj.question === 'string' &&
			obj.question.trim() !== '' &&
			typeof obj.shortCode === 'string' &&
			obj.shortCode.trim() !== '',
	);

	const disabled = () => {
		if (
			!activeItemIndex &&
			!!formik.values.ReportTitle &&
			!!formik.values.reportType &&
			!!formik.values.reportCategory &&
			!!formik.values.outputBtnLabel &&
			!!formik.values.maxTokensToBeUsed &&
			!!formik.values.temprature &&
			!!formik.values.reportDescription
		) {
			setIsDisabled(false);
		} else if (!!formik.values.prompt) {
			setIsDisabled(false);
		} else if (allNamesAreValid) {
			setIsDisabled(false);
		} else {
			setIsDisabled(true);
		}
	};

	useEffect(() => {
		disabled();
	}, [
		activeItemIndex,
		formik.values.ReportTitle,
		formik.values.reportType,
		formik.values.reportCategory,
		formik.values.outputBtnLabel,
		formik.values.maxTokensToBeUsed,
		formik.values.temprature,
		formik.values.reportDescription,
		formik.values.prompt,
		allNamesAreValid,
	]);

	const handleTemperature = (e: any) => {
		const { name, value } = e.target;
		const parsedValue = parseFloat(value);
		if (parsedValue > 2) {
			formik.setFieldValue(name, 2);
		} else if (parsedValue < 0) {
			formik.setFieldValue(name, 0);
		} else {
			formik.setFieldValue(name, parsedValue);
		}
	};

	const handleOrder = (e: any) => {
		const { name, value } = e.target;
		const parsedValue = parseInt(value);
		if (parsedValue < 0) {
			formik.setFieldValue(name, 0);
		} else {
			formik.setFieldValue(name, parsedValue);
		}
	};

	const handleKeyDown = (e: any) => {
		if (e.key === 'e') {
			e.preventDefault();
		}
	};

	return (
		<PageWrapper title='Create-Report-Type'>
			<Page>
				<Wizard
					buttonLable='Submit'
					isHeader
					stretch
					activeItemIndex={activeItemIndex}
					Loading={loading}
					buttonDisabled={isDisabled}
					setActiveItemIndex={setActiveItemIndex}
					onSubmit={formik.handleSubmit}>
					<WizardItem id='step1' title='Report Detail'>
						<div className='row g-4'>
							<div className='col-md-6'>
								<FormGroup id='ReportTitle' label='Report title' isFloating>
									<Input
										autoComplete='ReportTitle'
										value={formik.values.ReportTitle}
										isTouched={formik.touched.ReportTitle}
										invalidFeedback={formik.errors.ReportTitle}
										isValid={formik.isValid}
										onChange={formik.handleChange}
										onBlur={formik.handleBlur}
										onFocus={() => {
											formik.setErrors({});
										}}
									/>
								</FormGroup>
							</div>
							<div className='col-md-6'>
								<FormGroup id='reportCategory' label='Report Category' isFloating>
									<Input
										autoComplete='reportCategory'
										value={formik.values.reportCategory}
										isTouched={formik.touched.reportCategory}
										invalidFeedback={formik.errors.reportCategory}
										isValid={formik.isValid}
										onChange={formik.handleChange}
										onBlur={formik.handleBlur}
										onFocus={() => {
											formik.setErrors({});
										}}
									/>
								</FormGroup>
							</div>
							<div className='col-md-6'>
								<FormGroup id='outputBtnLabel' label='Output btn label' isFloating>
									<Input
										autoComplete='outputBtnLabel'
										value={formik.values.outputBtnLabel}
										isTouched={formik.touched.outputBtnLabel}
										invalidFeedback={formik.errors.outputBtnLabel}
										isValid={formik.isValid}
										onChange={formik.handleChange}
										onBlur={formik.handleBlur}
										onFocus={() => {
											formik.setErrors({});
										}}
									/>
								</FormGroup>
							</div>
							<div className='col-md-6'>
								<FormGroup
									id='maxTokensToBeUsed'
									label='Max tokens to be used (number)'
									isFloating>
									<Input
										type='number'
										autoComplete='maxTokensToBeUsed'
										value={formik.values.maxTokensToBeUsed}
										isTouched={formik.touched.maxTokensToBeUsed}
										invalidFeedback={formik.errors.maxTokensToBeUsed}
										isValid={formik.isValid}
										onKeyDown={handleKeyDown}
										onChange={formik.handleChange}
										onBlur={formik.handleBlur}
										onFocus={() => {
											formik.setErrors({});
										}}
									/>
								</FormGroup>
							</div>
							<div className='col-md-6'>
								<FormGroup id='temprature' label='Temprature' isFloating>
									<Input
										max={2}
										type='number'
										name='temprature'
										autoComplete='temprature'
										value={formik.values.temprature}
										isTouched={formik.touched.temprature}
										invalidFeedback={formik.errors.temprature}
										onKeyDown={handleKeyDown}
										isValid={formik.isValid}
										onChange={handleTemperature}
										onBlur={formik.handleBlur}
										onFocus={() => {
											formik.setErrors({});
										}}
									/>
								</FormGroup>
							</div>
							<div className='col-md-6'>
								<FormGroup id='order' label='Order' isFloating>
									<Input
										type='number'
										name='order'
										autoComplete='order'
										value={formik.values.order}
										isTouched={formik.touched.order}
										invalidFeedback={formik.errors.order}
										isValid={formik.isValid}
										onChange={handleOrder}
										onBlur={formik.handleBlur}
										onKeyDown={handleKeyDown}
										onFocus={() => {
											formik.setErrors({});
										}}
									/>
								</FormGroup>
							</div>
							<div className='col-md-6'>
								<Select
									id='select'
									size='lg'
									name='reportType'
									ariaLabel='Per'
									value={formik.values.reportType}
									isTouched={formik.touched.reportType}
									invalidFeedback={formik.errors.reportType}
									isValid={formik.isValid}
									onBlur={formik.handleBlur}
									onFocus={() => {
										formik.setErrors({});
									}}
									onChange={formik.handleChange}
									placeholder='Select a Reports Type'>
									<Option disabled style={{ fontSize: '16px' }}>
										Select a Reports Type
									</Option>

									<Option>Simple</Option>
									<Option>Standard</Option>
									<Option>Advanced</Option>
								</Select>
							</div>
							<div className='col-md-6'>
								<Select
									id='select'
									size='lg'
									name='model'
									ariaLabel='Per'
									value={formik.values.model}
									isTouched={formik.touched.model}
									invalidFeedback={formik.errors.model}
									isValid={formik.isValid}
									onBlur={formik.handleBlur}
									onFocus={() => {
										formik.setErrors({});
									}}
									onChange={formik.handleChange}
									placeholder='Select a Model'>
									<Option disabled>Select a Model</Option>
									{/* <Option value='GPT_TURBO'>gpt-3.5-turbo</Option> */}
									{/* <Option value='GPT_TURBO_16k'>gpt-3.5-turbo-16k</Option> */}
									{/* <Option value='DAVINCI_003'>text-davinci-003</Option> */}
									<Option value='GPT_4'>gpt-4</Option>
									<Option value='GPT_4o_MINI'>gpt-4o-mini</Option>
									<Option value='CLAUDE_SONNET'>
										claude-3-5-sonnet-20240620
									</Option>
								</Select>
							</div>
							<div className='col-md-12'>
								<Textarea
									id='reportDescription'
									placeholder='Report description'
									autoComplete='reportDescription'
									value={formik.values.reportDescription}
									isTouched={formik.touched.reportDescription}
									invalidFeedback={formik.errors.reportDescription}
									isValid={formik.isValid}
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
									onFocus={() => {
										formik.setErrors({});
									}}
								/>
							</div>
						</div>
					</WizardItem>
					<WizardItem id='step2' title='Report Question'>
						<div className='row g-4'>
							{formik?.values?.questions?.map((item: any, index: any) => {
								return (
									<div className='questionAndShortCode'>
										<div className='questionAndShortCodeInput'>
											<FormGroup
												id={`questions.${index}.question`}
												label='Question'
												isFloating>
												<Input
													name={`questions.${index}.question`}
													value={formik.values.questions[index]?.question}
													onChange={formik.handleChange}
												/>
											</FormGroup>
											<FormGroup
												id={`questions.${index}.shortCode`}
												label='Question shortCode'
												isFloating>
												<Input
													name={`questions.${index}.shortCode`}
													value={
														formik.values.questions[index]?.shortCode
													}
													onChange={formik.handleChange}
												/>
											</FormGroup>
										</div>
										<div className='questionAndShortCodeLength'>
											<FormGroup
												id={`questions.${index}.maxLength`}
												label='Max Length'
												isFloating>
												<Input
													type='number'
													defaultValue={0}
													name={`questions.${index}.maxLength`}
													value={
														formik.values.questions[index]?.maxLength ||
														0
													}
													onChange={formik.handleChange}
												/>
											</FormGroup>
											<FormGroup
												id={`questions.${index}.minLength`}
												label='Min Length'
												isFloating>
												<Input
													type='number'
													defaultValue={0}
													name={`questions.${index}.minLength`}
													value={
														formik.values.questions[index]?.minLength ||
														0
													}
													onChange={formik.handleChange}
												/>
											</FormGroup>
											<div className='col-md-2 w-auto toggle'>
												<label>Enable File Input</label>
												<Checks
													type='switch'
													checked={
														formik.values.questions[index]
															?.enableFileInput
													}
													onChange={formik.handleChange}
													name={`questions.${index}.enableFileInput`}
												/>
											</div>
										</div>
										<Button
											color='danger'
											isLight
											onClick={() => handleRemoveQuestion(index)}>
											Remove
										</Button>
									</div>
								);
							})}
							<div>
								<Button
									color='dark'
									icon='Add'
									size={'lg'}
									isLight
									onClick={handleAddQuestion}>
									Add
								</Button>
							</div>
						</div>
					</WizardItem>
					<WizardItem id='step3' title='Report Prompt'>
						<Textarea
							rows={15}
							id='prompt'
							placeholder='Prompt'
							autoComplete='prompt'
							value={formik.values.prompt}
							isTouched={formik.touched.prompt}
							invalidFeedback={formik.errors.prompt}
							isValid={formik.isValid}
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							onFocus={() => {
								formik.setErrors({});
							}}
						/>
					</WizardItem>
					<WizardItem id='step4' title='Preview'>
						<div className='row g-3'>
							<div className='col-9 offset-3'>
								<h4 className='mt-4'>Report Detail</h4>
							</div>

							<PreviewItem title='Report title' value={formik.values.ReportTitle} />
							<PreviewItem
								title='Report Category'
								value={formik.values.reportCategory}
							/>
							<PreviewItem
								title='Output btn label'
								value={formik.values.outputBtnLabel}
							/>
							<PreviewItem
								title='max tokens to be used (n umber)'
								value={formik.values.maxTokensToBeUsed}
							/>
							<PreviewItem title='Temperature' value={formik.values.temprature} />
							<PreviewItem title='Report Type' value={formik.values.reportType} />
							<PreviewItem title='Model' value={model?.[formik.values.model]} />
							<PreviewItem title='Order' value={formik.values.order} />
							<PreviewItem
								title='Report description'
								value={formik.values.reportDescription}
							/>

							<div className='col-9 offset-3'>
								<h3 className='mt-4'>Report Question</h3>
							</div>
							{formik?.values?.questions.map((ele: any, i: any) => {
								return (
									<>
										<PreviewItem
											title={`${i + 1}. Question`}
											value={ele?.question}
										/>
										<PreviewItem
											title={`${i + 1}. Question Short codes`}
											value={ele?.shortCode}
										/>
										<PreviewItem
											title={`${i + 1}. Max Length`}
											value={ele?.maxLength}
										/>
										<PreviewItem
											title={`${i + 1}. Min Length`}
											value={ele?.minLength}
										/>
									</>
								);
							})}

							<div className='col-9 offset-3'>
								<h3 className='mt-4'>Report Prompt</h3>
							</div>
							<PreviewItem title='Prompt' value={formik.values.prompt} />
						</div>
					</WizardItem>
				</Wizard>
			</Page>
		</PageWrapper>
	);
};

export default CreateReportType;
