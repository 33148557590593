import React, { useEffect, useState } from 'react';
import PageWrapper from '../../../../layout/PageWrapper/PageWrapper';
import Page from '../../../../layout/Page/Page';
import { useSelector, useDispatch } from 'react-redux';
import { useQuery } from '@apollo/client';
import Spinner from '../../../../components/bootstrap/Spinner';
import { GETALLREPORTSFEEDBACK } from '../../../../reducer/Types';
import { reportsFeedbackTableHeader } from '../../../../LocalData/TableData';
import { GET_ALL_REPORT_FEEDBACK, GET_ALL_USER_SUGGESTION } from '../../../../Api/Query';
import AllReportsFeedbackTable from '../../../../components/table/reports-feedback-table';
import ControlledAccordions from '../../../../components/adminAccordion';

const ReportsFeedback = () => {
	const { user, getAllReportsFeedback } = useSelector((state: any) => state);
	const [count, setCount] = useState(0);
	const [open, setOpen] = useState(false);
	const [allReportFeedbackLoading, setAllReportFeedbackLoading] = useState<boolean>(true);
	const [dataCount, setDataCount] = useState<number>(0);
	const [hasMore, setHasMore] = useState<boolean>(false);
	const [ReportFeedback, setReportFeedback] = useState<any>([]);
	// const navigate = useNavigate();

	const dispatch = useDispatch();
	const { loading, data, refetch } = useQuery(GET_ALL_REPORT_FEEDBACK, {
		variables: { offset: count },
		fetchPolicy: 'network-only',
		initialFetchPolicy: 'cache-only',
	});

	const { loading: suggestionLoading, data: suggestionData } = useQuery(GET_ALL_USER_SUGGESTION, {
		// variables: { offset: count },
		fetchPolicy: 'network-only',
		initialFetchPolicy: 'cache-only',
	});
	// console.log({ suggestionData });
	useEffect(() => {
		if (data?.getAllReportFeedback?.reportfeedback !== undefined) {
			setAllReportFeedbackLoading(false);
			setDataCount(data?.getAllReportFeedback?.count);
			setHasMore(data?.getAllReportFeedback?.hasMore);
			setReportFeedback((prev: any) => [
				...prev,
				...(data?.getAllReportFeedback?.reportfeedback || []),
			]);
		}
	}, [data]);

	useEffect(() => {
		if (ReportFeedback?.lenght !== dataCount)
			dispatch({ type: GETALLREPORTSFEEDBACK, payload: ReportFeedback });
		else dispatch({ type: GETALLREPORTSFEEDBACK, payload: ReportFeedback });
	}, [ReportFeedback]);

	return (
		<React.Fragment>
			<PageWrapper>
				{allReportFeedbackLoading ? (
					<div className='d-flex align-items-center justify-content-center h-100'>
						<Spinner isGrow={true} color={'dark'} size={'5vh'} />
					</div>
				) : (
					<Page>
						<AllReportsFeedbackTable
							tableHeader={reportsFeedbackTableHeader}
							heading='Reports Feedback List'
							GetReportsFeedbackData={getAllReportsFeedback}
							setCount={setCount}
							count={count}
							refetch={refetch}
							hasMore={hasMore}
							dataCount={dataCount}
							loading={loading}
						/>
					</Page>
				)}
				{suggestionLoading ? (
					<div className='d-flex align-items-center justify-content-center h-100'>
						<Spinner isGrow={true} color={'dark'} size={'5vh'} />
					</div>
				) : (
					<Page>
						<ControlledAccordions
							heading='User Suggestion List'
							data={suggestionData?.getAllUsersSuggestionByAdmin}
						/>
					</Page>
				)}
			</PageWrapper>
		</React.Fragment>
	);
};

export default ReportsFeedback;
